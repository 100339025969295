import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const path = window.location.pathname;
const userAgent = window.navigator.userAgent.toLowerCase();

if (path === "/live") {
  if (userAgent.includes("android"))
    window.location.href = "https://play.google.com/store/apps/details?id=com.celelive.live";
  else if (userAgent.includes("iphone"))
    window.location.href = "https://apps.apple.com/app/cele-live/id1630903687";
  else
    window.location.href = "https://celelive.com";
}
else {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
