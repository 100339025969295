import React from "react";
import "./App.css";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        document.querySelector("video").play();
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 30 }}>
                        <img src="logo.png" height={100} width={100} />
                        <h1 style={{ marginLeft: "10px", marginBottom: 0 }}>Cele Live</h1>
                    </div>
                    <p>
                        CELE LIVE is a popular live streaming platform for connecting, interacting and broadcasting. A lot of anchors and
                        users interact with you in real time and you can meet more interesting person without going out. Join CELE LIVE,
                        then you will not be lonely anymore!
                    </p>
                    <p>Experience live streaming like never before!</p>
                </div>
                <div>
                    <h1 style={{ marginBottom: 0, marginLeft: 20, paddingTop: 30 }}>Download Now</h1>
                    <div className="download-btn-container">
                        <a className="download-button" href="https://play.google.com/store/apps/details?id=com.celelive.live&hl=en&gl=US">
                            <img src="playstore.png" alt="Download on Google Play" />
                        </a>
                        <a className="download-button" href="https://apps.apple.com/my/app/cele-live/id1630903687">
                            <img src="appstore.png" alt="Download on the App Store" />
                        </a>
                    </div>
                </div>
            </header>
            <div className="divider"></div>
            <main style={{ textAlign: "center", alignItems: "center" }}>
                <div className="video-div">
                    <video autoplay loop muted  controls style={{ width: "100%", height: "auto" }}>
                        <source src="/video/celelive-ads.mp4" type="video/mp4" />
                    </video>
                </div>
                <section className="feature-section">
                    <h2 style={{ textAlign: "center" }}>Features</h2>
                    <ul>
                        <li>Watch live performances from your favorite celebrities</li>
                        <li>Interact with your idols in real-time</li>
                        <li>Join the CeleLive community and make new friends</li>
                        <li>Get exclusive access to behind-the-scenes content</li>
                        <li>And much more!</li>
                    </ul>
                </section>
                <section className="about-section">
                    <h2>About Cele Live</h2>
                    <p>
                        Cele Live is a new live streaming app that lets you watch your favorite celebrities perform live and interact with
                        them in real-time.
                    </p>
                    <p>Join the CeleLive community today and never miss a show!</p>
                </section>
            </main>
            <div className="divider"></div>
            <footer>
                <a target="_blank" className="link" href="https://celelive.com/celelive-eula.pdf">Terms of Use & Privacy Policy</a>
                <p>&copy; 2023 CeleLive. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default App;
